/** @jsx jsx */
import React, { FunctionComponent } from "react"
import { jsx, Box, Flex } from "theme-ui"

import "../styles/layout.css"

const Layout: FunctionComponent = ({ children }) => (
  <div>
    <Box bg="primary" py="1" style={{ width: "100%", position: "fixed" }}></Box>
    <Flex
      style={{
        margin: `0 auto`,
        maxWidth: 640,
        height: "100vh",
        padding: `1.5rem`,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {children}
    </Flex>
  </div>
)

export default Layout
